<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogCreatePartner" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="secondary">
          <div>
            <v-icon small class="mr-1">{{
              StoreObj.action == "CREATE" ? "mdi-plus" : "mdi-information"
            }}</v-icon>
            {{
              StoreObj.action == "CREATE" ? "Create Partner" : "Partner Details"
            }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCreatePartnerEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              dense
              outlined
              class="field_height field_label_size FontSize mt-4"
              :rules="[(v) => !!v || 'Required']"
              label="Partner Name *"
              v-model="create_partner.partner_name"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              :readonly="StoreObj.action == 'EDIT' ? true : false"
              class="field_height field_label_size FontSize"
              :rules="[
                (v) => !!v || 'Required',
                (v) =>
                  /^(?=[a-zA-Z0-9@._%+-]{0,100}$)[a-z A-Z 0-9-]{0,100}$/.test(
                    v
                  ) || 'Invalid',
                (v) => (v.length > 0 && v.length <= 5) || 'Invalid Value',
              ]"
              label="Partner Code *"
              v-model="create_partner.partner_code"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              :readonly="StoreObj.action == 'EDIT' ? true : false"
              class=" field_height field_label_size FontSize"
              label="Email ID *"
              :rules="[
                (v) => !!v || 'Required',
                (v) => /.+@.+/.test(v) || 'Invalid',
              ]"
              v-model="create_partner.partner_email_id"
            ></v-text-field>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="5">
                <v-select
                  outlined
                  dense
                  :items="GetAllSupportedCountryList"
                  class="field_height field_label_size FontSize"
                  label="Country Code *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_partner.partner_country_code"
                >
                  <template slot="selection" slot-scope="{ item }">
                    {{ item.country_code }}
                  </template>
                  <template slot="item" slot-scope="{ item }"
                    >{{ item.country_name }}({{ item.country_code }})
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="7">
                <v-text-field
                  outlined
                  dense
                  class="field_height field_label_size FontSize ml-2"
                  label="Contact Number *"
                  :rules="[
                    (v) =>
                      /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(v) ||
                      'Invalid',
                    (v) => (v && v.length < 11) || 'Invalid',
                    (v) => (v && v.length > 9) || 'Invalid',
                  ]"
                  v-model="create_partner.partner_contact_number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-select
              outlined
              dense
              :items="GetAllSupportedCountryList"
              item-value="country_name"
              item-text="country_name"
              :readonly="StoreObj.action == 'EDIT' ? true : false"
              class="field_height field_label_size FontSize"
              label="Country Name *"
              :rules="[(v) => !!v || 'Required']"
              v-model="create_partner.partner_country_name"
            >
              <template slot="item" slot-scope="{ item }"
                >{{ item.country_name }}({{ item.country_code }})
              </template>
            </v-select>
            <v-text-field
              outlined
              dense
              class="field_height field_label_size FontSize"
              label="City *"
              :readonly="StoreObj.action == 'EDIT' ? true : false"
              :rules="[
                (v) => !!v || 'Required',
                (v) => (v && v.length > 5) || 'Invalid',
                (v) => (v && v.length < 21) || 'Invalid',
              ]"
              v-model="create_partner.partner_city"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              :type="show ? 'text' : 'password'"
              v-if="StoreObj.action == 'CREATE'"
              class="field_height field_label_size FontSize"
              label="Enter Temporary Password *"
              :rules="[
                (v) => !!v || 'Required',
                (v) => (v && v.length > 5) || 'Invalid',
                (v) => (v && v.length < 21) || 'Invalid',
              ]"
              v-model="create_partner.temporary_password"
            >
              <template v-slot:append>
                <v-icon small color="primary" @click="show = !show">{{
                  show ? "mdi-eye" : "mdi-eye-off"
                }}</v-icon>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            class="borderRadius text-capitalize"
            color="secondary"
            @click="dialogCreatePartnerEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel
          </v-btn>
          <v-btn
            small
            :loading="loading"
            class="borderRadius text-capitalize"
            color="secondary"
            @click="validateFormMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetAllSupportedCountry } from "@/mixins/GetAllSupportedCountry.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { CreatePartner, UpdatePartner } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    dialogCreatePartner: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  mixins: [GetAllSupportedCountry],
  data: () => ({
    Toggle: 0,
    show: false,
    loading: false,
    create_partner: {
      partner_code: "",
      partner_name: "",
      partner_email_id: "",
      partner_country_code: "",
      partner_contact_number: "",
      partner_country_name: "",
      partner_city: "",
    },
    SnackBarComponent: {},
  }),
  watch: {
    dialogCreatePartner(val) {
      if (val == true) {
        if (this.StoreObj.action == "EDIT") {
          this.create_partner.partner_code = this.StoreObj.partner_code;
          this.create_partner.partner_name = this.StoreObj.partner_name;
          this.create_partner.partner_email_id = this.StoreObj.partner_email_id;
          this.create_partner.partner_country_code = this.StoreObj.partner_country_code;
          this.create_partner.partner_contact_number = this.StoreObj.partner_contact_number;
          this.create_partner.partner_country_name = this.StoreObj.partner_country_name;
          this.create_partner.partner_city = this.StoreObj.partner_city;
        } else {
          this.StoreObj.action = "CREATE";
          this.create_partner.partner_code = "";
          this.create_partner.partner_name = "";
          this.create_partner.partner_email_id = "";
          this.create_partner.partner_country_code = "";
          this.create_partner.partner_contact_number = "";
          this.create_partner.partner_country_name = "";
          this.create_partner.partner_city = "";
        }
      }
    },
  },
  mounted() {
    this.GetAllSupportedCountryMethod("ACTIVE");
  },
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        if (this.StoreObj.action == "CREATE") {
          this.createPartnerMethod();
        } else {
          this.editPartnerMethod();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks(*) are mandatory",
        };
      }
    },
    async editPartnerMethod() {
      try {
        this.loading = true;
        var inputParams = {
          partner_id: this.StoreObj.partner_id,
          partner_name: this.create_partner.partner_name,
          partner_country_code: this.create_partner.partner_country_code,
          partner_contact_number: this.create_partner.partner_contact_number,
        };
        let result = await API.graphql(
          graphqlOperation(UpdatePartner, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdatePartner);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.overlay = true;
          this.dialogCreatePartnerEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    async createPartnerMethod() {
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(CreatePartner, {
            input: this.create_partner,
          })
        );
        var ResultObject = JSON.parse(result.data.CreatePartner);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.overlay = true;
          this.dialogCreatePartnerEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    dialogCreatePartnerEmit(Toggle) {
      // if (this.$refs.form !== undefined) {
      //   this.$refs.form.reset();
      // }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
