<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog
      v-model="dialogActivateInactivatePartners"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div
            v-text="
              StoreObj.partner_status == 'ACTIVE' ? 'Deactivate' : 'Activate'
            "
          ></div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialogActivateInactivatePartnersEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text align="center">
          <v-icon
            class="mt-5"
            size="50px"
            :color="StoreObj.partner_status == 'ACTIVE' ? 'red' : 'green'"
            >{{
              StoreObj.partner_status == "ACTIVE" ? "mdi-close" : "mdi-check"
            }}</v-icon
          >
          <div>
            Are you Sure you want to
            <span
              v-text="
                StoreObj.partner_status == 'ACTIVE' ? 'Deactivate' : 'Activate'
              "
            ></span>
            {{ StoreObj.partner_name }} ?
          </div>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="borderRadius"
            color="secondary"
            outlined
            @click="dialogActivateInactivatePartnersEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn
            small
            class="mr-2 borderRadius"
            color="secondary"
            @click="changeStatus()"
            :loading="loading"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { ActivateOrDeactivePartner } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    dialogActivateInactivatePartners: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    loading: false,
    SnackBarComponent: {},
  }),
  methods: {
    async changeStatus() {
      try {
        var inputParams = {
          partner_id: this.StoreObj.partner_id,
          action:
            this.StoreObj.partner_status == "ACTIVE"
              ? "DEACTIVATE"
              : "ACTIVATE",
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(ActivateOrDeactivePartner, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.ActivateOrDeactivePartner);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogActivateInactivatePartnersEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    dialogActivateInactivatePartnersEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
