<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <CreatePartnerDialog
      :StoreObj="StoreObj"
      :dialogCreatePartner="dialogCreatePartner"
      @clicked="dialogCreatePartnerEmit"
    />
    <ActivateDeactivatePartnerDialog
      :StoreObj="StoreObj"
      :dialogActivateInactivatePartners="dialogActivateInactivatePartners"
      @clicked="dialogActivateInactivatePartnersEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">
              Partners
            </div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field
              dense
              label="Search Partners"
              outlined
              class="FontSize field_label_size field_height borderRadius mt-6 mr-3"
              v-model="search"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="primary" class="mr-1">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <v-tooltip bottom color="#474747">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  small
                  color="primary"
                  @click="
                    (dialogCreatePartner = true), (StoreObj.action = 'CREATE')
                  "
                  ><v-icon small class="mr-1">mdi-plus</v-icon>Create
                </v-btn>
              </template>
              <span>Create New Partner</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="pt-0">
        <v-data-table
          dense
          class="elevation-0"
          :headers="partnerHeaders"
          :items="GetAllPartnersList"
          :disable-sort="$vuetify.breakpoint.xs ? true : false"
          :hide-default-footer="GetAllPartnersList.length == 0"
          :search="search"
          :footer-props="{
            'items-per-page-options': [100, 200, 300, 400, 500],
          }"
          :items-per-page="100"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllPartnersList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.partner_name`]="{ item }">
            <div class="FontSize">
              {{ item.partner_name }}
            </div>
          </template>
          <template v-slot:[`item.partner_code`]="{ item }">
            <div class="FontSize">
              {{ item.partner_code != undefined ? item.partner_code : "-" }}
            </div>
          </template>
          <template v-slot:[`item.partner_email_id`]="{ item }">
            <div class="FontSize">
              {{ item.partner_email_id }}
            </div>
          </template>
          <template v-slot:[`item.partner_full_number`]="{ item }">
            <div class="FontSize">
              {{ item.partner_full_number }}
            </div>
          </template>
          <template v-slot:[`item.partner_created_on`]="{ item }">
            <div class="FontSize">
              {{ new Date(item.partner_created_on).toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.partner_status`]="{ item }">
            <div
              class="FontSize font-weight-bold"
              :class="
                item.partner_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'
              "
            >
              {{ item.partner_status }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom v-if="item.partner_name !== 'SureCreds'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :color="'green'"
                  @click="checkItem(item, 'EDIT')"
                  ><v-icon small>mdi-pencil</v-icon></v-btn
                >
              </template>
              <span>Edit {{ item.partner_name }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.partner_name !== 'SureCreds'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :color="item.partner_status == 'ACTIVE' ? 'red' : 'green'"
                  @click="checkItem(item, 'ACTION')"
                  ><v-icon small>{{
                    item.partner_status == "ACTIVE" ? "mdi-close" : "mdi-check"
                  }}</v-icon></v-btn
                >
              </template>
              <span>{{
                item.partner_status == "ACTIVE"
                  ? `Deactivate ${item.partner_name}`
                  : `Activate ${item.partner_name}`
              }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import CreatePartnerDialog from "@/components/Partners/Dialogs/CreatePartnerDialog.vue";
import ActivateDeactivatePartnerDialog from "@/components/Partners/Dialogs/ActivateDeactivatePartnerDialog.vue";
import { GetAllPartners } from "@/mixins/GetAllPartners.js";
import Snackbar from "../../components/Extras/Snackbar.vue";
export default {
  components: {
    ActivateDeactivatePartnerDialog,
    CreatePartnerDialog,
    Overlay,
    Snackbar,
  },
  mixins: [GetAllPartners],
  data: () => ({
    StoreObj: {},
    SnackBarComponent: {},
    search: "",
    dialogCreatePartner: false,
    dialogActivateInactivatePartners: false,
    partnerHeaders: [
      {
        text: "Partner Code",
        value: "partner_code",
      },
      {
        text: "Partner Name",
        value: "partner_name",
      },
      {
        text: "Email ID",
        value: "partner_email_id",
      },
      {
        text: "Contact No.",
        value: "partner_full_number",
      },
      {
        text: "Created On",
        value: "partner_created_on",
      },
      {
        text: "Status",
        value: "partner_status",
      },
      {
        text: "Actions",
        value: "Actions",
      },
    ],
  }),
  mounted() {
    this.GetAllPartnersMethod();
  },
  methods: {
    dialogActivateInactivatePartnersEmit(Toggle) {
      this.dialogActivateInactivatePartners = false;
      if (Toggle == 2) {
        this.GetAllPartnersMethod();
      }
    },
    checkItem(item, ACTION) {
      this.StoreObj = item;
      this.StoreObj.action = ACTION;
      if (ACTION == "EDIT") {
        this.dialogCreatePartner = true;
        this.StoreObj = item;
      } else if (ACTION == "ACTION") {
        this.dialogActivateInactivatePartners = true;
      }
    },
    dialogCreatePartnerEmit(Toggle) {
      this.dialogCreatePartner = false;
      if (Toggle == 2) {
        this.GetAllPartnersMethod();
      }
    },
  },
};
</script>
